<img [src]="logo" width="100%" class="logo" alt="Logomarca do Portugol Webstudio" />

<nav>
  <button mat-ripple (click)="newTab.emit()">
    <svg-icon src="assets/mdi/file-plus-outline.svg" svgClass="text-secondary" svgAriaLabel="Ícone de novo arquivo" />
    <span>Novo Arquivo</span>
  </button>

  <button mat-ripple (click)="fileInput.click()">
    <svg-icon
      src="assets/mdi/folder-open-outline.svg"
      svgClass="text-primary"
      svgAriaLabel="Ícone de pasta para ação de abrir arquivo"
    />

    <span>Abrir Arquivo</span>
    <input #fileInput type="file" hidden multiple accept=".por,text/plain" (change)="openFile($event)" />
  </button>

  <button mat-ripple (click)="openExamplesDialog()">
    <svg-icon
      src="assets/mdi/book-open-page-variant-outline.svg"
      svgClass="text-primary"
      svgAriaLabel="Ícone de livro para ação de abrir exemplo"
    />

    <span>Abrir Exemplo</span>
  </button>

  <button mat-ripple (click)="help.emit()">
    <svg-icon src="assets/mdi/help.svg" svgClass="text-quaternary" alt="Ícone de ajuda" />
    <span>Ajuda</span>
  </button>

  <a
    href="https://github.com/dgadelha/Portugol-Webstudio/discussions/categories/ideias-e-sugest%C3%B5es"
    target="_blank"
    rel="external noreferrer noopener nofollow"
    mat-ripple
    (click)="gaService.event('suggest_features', 'Aba Inicial', 'Enviar sugestões')"
  >
    <svg-icon
      src="assets/mdi/lightbulb-on-outline.svg"
      svgClass="text-secondary"
      svgAriaLabel="Ícone de lâmpada para ação de enviar sugestões"
    />

    <span>Sugestões</span>
  </a>

  <a
    href="https://github.com/dgadelha/Portugol-Webstudio/discussions/categories/d%C3%BAvidas-perguntas"
    target="_blank"
    rel="external noreferrer noopener nofollow"
    mat-ripple
    (click)="gaService.event('ask_questions', 'Aba Inicial', 'Enviar dúvidas')"
  >
    <svg-icon
      src="assets/mdi/comment-question-outline.svg"
      svgClass="text-quaternary"
      svgAriaLabel="Ícone de balão de diálogo para ação de enviar dúvidas"
    />

    <span>Dúvidas e Perguntas</span>
  </a>

  <a
    href="https://github.com/dgadelha/Portugol-Webstudio/issues/new?template=bug.yml"
    target="_blank"
    rel="external noreferrer noopener nofollow"
    mat-ripple
    (click)="gaService.event('ask_questions', 'Aba Inicial', 'Enviar dúvidas')"
  >
    <svg-icon
      src="assets/mdi/bug-outline.svg"
      svgClass="text-tertiary"
      svgAriaLabel="Ícone de inseto para ação de reportar um problema"
    />

    <span>Reportar um problema</span>
  </a>

  <a
    href="https://github.com/dgadelha/Portugol-Webstudio"
    target="_blank"
    rel="external noreferrer noopener nofollow"
    mat-ripple
    (click)="gaService.event('view_source_code', 'Aba Inicial', 'Ver código-fonte')"
  >
    <svg-icon src="assets/icon-github.svg" svgAriaLabel="Ícone do GitHub" />
    <span>Código-fonte</span>
  </a>
</nav>

<section class="info">
  <h4><svg-icon src="assets/mdi/newspaper.svg" svgAriaLabel="Ícone de jornal para notícias" />Novidades</h4>
  <p>
    <strong>23/09/2024:</strong> Ajuste na fonte da seção Ajuda para estar disponível offline, mantendo uma boa
    experiência sem depender de conexão com a internet.
  </p>
  <p>
    <strong>22/09/2024:</strong> Novo modo de salvar arquivos sem manter compatibilidade com o Portugol Studio.
    Alteração do comportamento de atualizações do PWA.
  </p>
  <p>
    <strong>13/09/2024:</strong> Melhorias no controle de abas: diálogos próprios para confirmação de renomear e fechar
    abas. Ação <strong>Salvar como</strong> com opções avançadas para salvar o código.
  </p>
</section>

<footer>
  <summary>Versão: <span matTooltip="13/10/2024 02:16:48 AM UTC">d35aa36</span></summary>
</footer>
